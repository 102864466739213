<template>
    <section class="container"></section>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const { logOut } = useIdentity();

onMounted(async () => {
    await logOut();
    await navigateTo(localePath("/"), { external: true }); // full reload
});
</script>
